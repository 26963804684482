@import '~bootstrap/scss/bootstrap';
@import 'variables';
@import 'typography';
@import 'about';

body{
  background:$red;
  background:#000000;
  &.dark{
    background:$darkGrey2;
  }
  text-align:center;
  form{
    max-width:360px;
    margin:auto;
    button{
      &.btn{
        padding:8px 14px;
        background-color:#5B5B5B;
        background-color:#6e6e6e;
        border:none;
        &:hover{
          background-color:#444343;;
        }
      }
      &:first-of-type{
        float:left;
      }
      &:last-of-type{
        float:right;
      }
    }
  }
  select, textarea{
    margin:auto;
    margin-bottom:10px;
  }
  .status{
    height:24px;
    color:$light-blue;
    font-weight:600;
  }
  footer{
    a{
      padding:20px 10px;
      display:inline-block;
    }
    .copyright{
      background:$red;
      background:#000000;
      padding:6px 10px 10px 10px;
      small{
        color:rgba(255,255,255,.75);
      }
    }
  }
  .type-pattern{
    position:fixed;
    top:-100px;
    bottom:-100px;
    left:-100px;
    right:-100px;
    z-index:-1;
    font-size: 282px;
    line-height: 85px;
    text-align:left;
    color:#e5e5e5;
    color:#F99182;
    color:#0F0F0F;
    overflow-wrap: break-word;
  }
}

html, body, #root {
  height: 100%;
  margin: 0;
}
.wrapper{
  //min-height: 100%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -102px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
footer,
.push {
  height: 100px;
}