body{
  font-family: "Figtree", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-optical-sizing: auto;
  font-weight:400;
  font-style: normal;
}
h1{
  color: #ffffff;
  font-family: "Fraunces", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
          "SOFT" 0,
          "WONK" 0;
}

p{
 // text-align:center;
  color:$white;

}

p{
  margin-bottom:0;
  font-size:16px;
  line-height:20px;
}
a{
  color:$white;
  text-decoration:none;
  font-weight:700;

  &:hover{
    color: $light-blue;
  }
}

small{
  color:$white;
  font-size:12px;
  font-weight:700;
}